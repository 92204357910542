<template>
    <div>

        <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
        <br />
        <tabs
                :options="menuOptions"
                v-model="active"
                labelField="label"
                idField="key"
                showItems="6"
        >
            <template slot="menu-display" slot-scope="props">
                {{props.value.label}}
                <span v-if="countSectionError(props.value.key)" class="tab-nav-error">
                    {{ countSectionError(props.value.key) }}
                </span>
            </template>
        </tabs>
        <div class="form-section" v-show="active =='details'" >

            <div class="horizontal-form-row">
                <label class="horizontal-form-label" for="name">Trigger Name</label>

                <div class="horizontal-form-input-container">
                    <div class="horizontal-form-input" style="width: 300px" :class="{'has-error': errors.has('name') }">
                        <input
                                id="name"
                                name="name"
                                class="input w-input"
                                v-model="trigger.name"
                                v-validate="'required|max:45'"
                                data-vv-as="name"
                        />
                    </div>
                    <span v-show="errors.has('name')" class="status-block error-block field-error">{{ errors.first('name') }}</span>
                </div>
            </div>


            <div class="horizontal-form-row">
                <label class="horizontal-form-label" for="description">Description</label>
                <div class="form-input-container">
                    <div class="horizontal-form-input" style="width: 100%" :class="{'has-error': errors.has('description') }">
                    <textarea
                            id="description"
                            name="description"
                            class="input w-input"
                            v-model="trigger.description"
                            v-validate="'required|max:1000'"
                            rows="4"
                    ></textarea>
                    </div>
                    <span v-show="errors.has('description')" class="status-block error-block field-error">{{ errors.first('description') }}</span>
                </div>
            </div>


            <div class="horizontal-form-row">
                <label class="horizontal-form-label">Starting On</label>
                <div class="horizontal-form-input-container">
                    <div class="horizontal-form-input" style="width: 75px" :class="{'has-error': errors.has('start') }">
                        <input
                                id="start"
                                name="start"
                                class="input w-input"
                                v-model="trigger.start"
                                data-vv-as="start"
                                v-validate="'required|integer|min_value:0|max_value:999'"
                        />
                    </div>

                    <div class="horizontal-form-input">
                        Days
                    </div>

                    <div class="horizontal-form-input" style="width: 250px" :class="{'has-error': errors.has('trigger_reference') }">
                        <dropdown
                                forceChoice
                                id="trigger_reference"
                                name="trigger_reference"
                                :options="trigger_list"
                                v-model="trigger.trigger_reference"
                                v-validate="'required'"
                                data-vv-as="trigger reference"
                        ></dropdown>
                    </div>

                    <span v-show="errors.has('start')" class="status-block error-block field-error">{{ errors.first('start') }}</span>
                    <span v-show="errors.has('trigger_reference')" class="status-block error-block field-error">{{ errors.first('trigger_reference') }}</span>
                </div>
            </div>


            <div class="horizontal-form-row" v-show="trigger.trigger_reference == 'After account is past due'" >
                <label class="horizontal-form-label" for="repeat">Repeat Every</label>
                <div class="horizontal-form-input-container">
                    <div class="horizontal-form-input" style="width: 75px" :class="{'has-error': errors.has('repeat') }">
                        <input
                          id="repeat"
                          name="repeat"
                          placeholder="3"
                          class="input w-input"
                          v-model="trigger.repeat"
                          v-validate="'integer|min_value:0|max_value:999'"
                        />
                    </div>
                    <div class="horizontal-form-input" >
                        days, for a maximum of
                    </div>

                    <div class="horizontal-form-input" style="width: 75px">
                        <input
                          id="max_repeat"
                          name="max_repeat"
                          class="input w-input"
                          v-model="trigger.max_repeat"
                          v-validate="'integer|min_value:0|max_value:999'"
                        />
                    </div>

                    <div class="horizontal-form-input" >
                        times.
                    </div>
                    <span v-show="errors.has('max_repeat')" class="status-block error-block field-error">{{ errors.first('max_repeat') }}</span>
                </div>
            </div>



            <div class="horizontal-form-row">
              <label class="horizontal-form-label" for="event_type">Event Type</label>

              <div class="horizontal-form-input-container">
                <div class="horizontal-form-input" style="width: 280px" :class="{'has-error': errors.has('event_type') }">
                  <dropdown
                    :options="event_types"
                    v-model="trigger.Event.event_type_id"
                    id="event_type"
                    name="event_type"
                    idField="id"
                    labelField="name"
                    v-validate="'required'"
                    data-vv-as="event type"
                  >
                  </dropdown>
                </div>
                  <div class="horizontal-form-input">

                    <dropdown-menu
                      align="left"
                      width="350"
                      name="event_type"
                      ref="event_type"
                    >
                      <template slot="trigger">
                        <button class="w-button secondary-btn">
                          <span class="icon"></span> Add
                        </button>
                      </template>
                      <template slot="dropdown" >
                        <div>
                          <div class="form-section">

                            <strong>Add Event Type</strong><br /><br />
                            <input type="text"
                                   class="w-input input"
                                   v-model="newEventType"
                                   v-validate="'max:45'"
                                   placeholder="Name"
                            />
                          </div>
                          <div class="modal-footer modal-footer-padding">
                            <a class="close-link" @click="$refs.event_type.away()">Cancel</a>
                            <button class="primary-btn w-button" @click="saveEventType()"> Save Event Type</button>
                          </div>
                        </div>
                      </template>
                    </dropdown-menu>
                </div>
                <span v-show="errors.has('event_type')" class="status-block error-block field-error">{{ errors.first('event_type') }}</span>
              </div>
            </div>



          <div class="horizontal-form-row">
            <label class="horizontal-form-label">&nbsp;</label>
            <div class="horizontal-form-input-container">
              <div class="horizontal-form-input" :class="{'has-error': errors.has('deny_access') }">
                <checkbox
                  id="deny_access"
                  name="deny_access"
                  v-model="trigger.deny_access"
                  label="Deny Access when this event is triggered"
                ></checkbox>
                <span class="subdued">Access is restored on payment that brings account current.</span>
              </div>
            </div>
          </div>
          <br />

          <div class="horizontal-form-row">
            <label class="horizontal-form-label" for="attachment">Change Lease Standing</label>
            <div class="horizontal-form-input-container">
              <div class="horizontal-form-input" style="width: 200px" :class="{'has-error': errors.has('lease_standing') }">
                <dropdown
                  id="lease_standing"
                  name="lease_standing"
                  :options="lease_standings"
                  labelField="name"
                  idField="id"
                  v-model="trigger.lease_standing_id"
                >
                </dropdown>
              </div>

              <div class="horizontal-form-input">

                <dropdown-menu
                  align="left"
                  width="350"
                  name="lease_standing"
                  ref="lease_standing"
                >
                  <template slot="trigger">
                    <button class="w-button secondary-btn">
                      <span class="icon"></span> Add
                    </button>
                  </template>
                  <template slot="dropdown" >
                    <div>
                      <div class="form-section">

                        <strong>Add Lease Standing</strong><br /><br />
                        <input type="text"
                               class="w-input input"
                               v-model="newLeaseStanding"
                               v-validate="'max:45'"
                               placeholder="Name"
                        />
                      </div>
                      <div class="modal-footer modal-footer-padding">
                        <a class="close-link" @click="$refs.lease_standing.away()">Cancel</a>
                        <button class="primary-btn w-button" @click="saveLeaseStanding()"> Save Lease Standing</button>
                      </div>
                    </div>
                  </template>
                </dropdown-menu>
              </div>

              <span v-show="errors.has('lease_standing')" class="status-block error-block field-error">{{ errors.first('lease_standing') }}</span>
            </div>
          </div>

          <div class="horizontal-form-row">
            <label class="horizontal-form-label">&nbsp;</label>
            <div class="horizontal-form-input-container">
              <div class="horizontal-form-input" :class="{'has-error': errors.has('deny_access') }">
                <checkbox
                  id="overlock"
                  name="overlock"
                  v-model="trigger.overlock"
                  label="Overlock this unit when this trigger fires"
                ></checkbox>
                <span class="subdued">Access is restored on payment that brings account current.</span>
              </div>
            </div>
          </div>
          <br />



          <div class="horizontal-form-row">
            <label class="horizontal-form-label" for="attachment">Generate a Document</label>
            <div class="horizontal-form-input-container">
              <div class="horizontal-form-input" style="width: 283px" :class="{'has-error': errors.has('attachment') }">
                <dropdown
                  id="attachment"
                  name="attachment"
                  :options="documents"
                  labelField="name"

                  v-model="trigger.Attachment.Document"
                >
                  <template slot="option" scope="props">
                    <strong>{{props.data.name}}</strong><br />
                    {{props.data.description}}
                  </template>

                </dropdown>
              </div>
              <span v-show="errors.has('attachment')" class="status-block error-block field-error">{{ errors.first('attachment') }}</span>
            </div>
          </div>



          <div class="horizontal-form-row" v-show="trigger.Attachment.Document.id">
            <label class="horizontal-form-label" for="to_print">&nbsp;</label>
            <div class="horizontal-form-input-container">
              <div class="horizontal-form-input" :class="{'has-error': errors.has('to_print') }">
                <checkbox
                  id="to_print"
                  name="to_print"
                  v-model="trigger.Attachment.to_print"
                  label="Print this document"
                ></checkbox>

              </div>
            </div>
          </div>
        </div>



      <div class="form-section" v-show="active =='events'" >

        <div class="horizontal-form-row">
          <div class="horizontal-form-input-container">
            <div class="horizontal-form-input">
              <div class="checkbox">

                <input
                  type="checkbox"
                  v-model="trigger.Event.todo"
                  id="event_active"
                  name="event_active"
                  data-name="event_active"
                  class="w-checkbox-input">
                <label
                  for="event_active"
                  class="w-form-label">Create a todo for this event</label>
              </div>
            </div>

          </div>
        </div>


        <div class="horizontal-form-row" v-show="trigger.Event.todo">
          <label class="horizontal-form-label" for="message">Details</label>
          <div class="horizontal-form-input-container">
            <div class="horizontal-form-input" style="width: 400px" :class="{'has-error': errors.has('details') }">
                        <textarea
                          id="details"
                          rows="4"
                          name="details"
                          class="input w-input"
                          v-model="trigger.Event.details"
                          v-validate="trigger.Event.todo ? 'required|max:1000' : ''"
                          data-vv-as="email message"
                        ></textarea>
            </div>
            <span v-show="errors.has('details')" class="status-block error-block field-error">{{ errors.first('details') }}</span>
          </div>
        </div>



        <div class="horizontal-form-row" v-show="trigger.Event.todo">
          <label class="horizontal-form-label" for="admin_id">Assign To</label>
          <div class="horizontal-form-input-container">
            <div class="horizontal-form-input" :class="{'has-error': errors.has('admin_id') }">
              <autocomplete
                id="admin_id"
                name="admin_id"
                :options="admins"
                showAll
                labelField="name"
                idField="id"
                v-model="trigger.Event.contact_id"
                v-validate="trigger.Event.todo ? 'required' : ''"
              >
              </autocomplete>
              <span v-show="errors.has('admin_id')" class="status-block error-block field-error">{{ errors.first('admin_id') }}</span>
              <div class="info-section" v-show="trigger.Event.contact_id === -1">
                <span>This will be shown to everyone until someone completes it.</span>
              </div>
            </div>
          </div>
        </div>


      </div>

        <div class="form-section" v-show="active =='fees'" >

            <div class="horizontal-form-row">
                <div class="horizontal-form-input-container">
                    <div class="horizontal-form-input">
                        <div class="checkbox">
                            <input
                                type="checkbox"
                                v-model="trigger.Fee.active"
                                id="fee_active"
                                name="fee_active"
                                data-name="fee_active"
                                class="w-checkbox-input">
                            <label
                                for="fee_active"
                                class="w-form-label">Charge a fee when this is triggered</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="horizontal-form-row" v-show="trigger.Fee.active">
                <label class="horizontal-form-label" for="product_id">Product to Add</label>

                <div class="horizontal-form-input-container">
                    <div class="horizontal-form-input" style="width: 300px" :class="{'has-error': hasSubmitted }">
                        <autocomplete
                            :options="products"
                            id="product_id"
                            name="product_id"
                            data-vv-as="product"
                            showAll
                            labelField="name"
                            v-model="trigger.Fee.Product"
                            v-validate="trigger.Fee.active ? 'required' : ''"
                            @search="searchProducts"
                            ></autocomplete>
                    </div>
                    <span v-show="hasSubmitted" class="status-block error-block field-error">Please choose a product to add.</span>
                </div>
            </div>


            <div class="horizontal-form-row" v-show="trigger.Fee.active">
                <label class="horizontal-form-label" for="amount">Fee Amount</label>

                <div class="horizontal-form-input-container">
                    <div class="horizontal-form-input" style="width: 75px" :class="{'has-error': errors.has('amount') }">
                        <input
                                id="amount"
                                name="amount"
                                class="input w-input"
                                v-model="trigger.Fee.amount"
                                data-vv-as="amount"
                                v-validate="trigger.Fee.active ? 'required|decimal:2|min_value:0|max_value:99999999.99' : ''"
                        />
                    </div>
                    <div class="horizontal-form-input" style="width: 75px" :class="{'has-error': errors.has('fee_type') }">
                        <dropdown
                            forceChoice
                            name="fee_type"
                            id="fee_type"
                            :options="['$','%']"
                            v-model="trigger.Fee.type"
                        >
                        </dropdown>

                    </div>
                    <div class="horizontal-form-input" v-show="trigger.Fee.type == '%'">
                        OF
                    </div>

                    <div class="horizontal-form-input" style="width: 200px" :class="{'has-error': errors.has('fee_type') }" v-show="trigger.Fee.type == '%'">
                        <dropdown
                                forceChoice
                            name="fee_type"
                            id="fee_type"
                            :options="fee_references"
                            v-model="trigger.Fee.reference"
                        >
                        </dropdown>
                    </div>


                    <span v-show="errors.has('amount')" class="status-block error-block field-error">{{ errors.first('amount') }}</span>
                    <span v-show="errors.has('fee_type')" class="status-block error-block field-error">{{ errors.first('fee_type') }}</span>
                </div>
            </div>

        </div>


        <div class="form-section" v-show="active =='email'" >
            <div class="horizontal-form-row">
                <div class="horizontal-form-input-container">
                    <div class="horizontal-form-input">
                        <div class="checkbox">
                            <input
                                type="checkbox"
                                v-model="trigger.Email.active"
                                id="email_active"
                                name="email_active"
                                data-name="email_active"
                                class="w-checkbox-input">
                            <label
                                for="email_active"
                                class="w-form-label">Send an email when this is triggered</label>
                        </div>
                    </div>

                </div>
            </div>

            <div class="horizontal-form-row" v-show="trigger.Email.active">
                <label class="horizontal-form-label" for="subject">Email Subject</label>

                <div class="horizontal-form-input-container">
                    <div class="horizontal-form-input" style="width: 300px" :class="{'has-error': errors.has('subject') }">
                        <input
                                id="subject"
                                name="subject"
                                class="input w-input"
                                v-model="trigger.Email.subject"
                                v-validate="trigger.Email.active ? 'required|max:78' : ''"
                                data-vv-as="email subject"
                        />
                    </div>
                    <span v-show="errors.has('subject')" class="status-block error-block field-error">{{ errors.first('subject') }}</span>
                </div>
            </div>




            <div class="horizontal-form-row" v-show="trigger.Email.active">
                <label class="horizontal-form-label" for="message">Email Content</label>
                <div class="horizontal-form-input-container">
                    <div class="horizontal-form-input" style="width: 400px" :class="{'has-error': errors.has('message') }">
                        <textarea
                                id="message"
                                rows="8"
                                name="message"
                                class="input w-input"
                                v-model="trigger.Email.message"
                                v-validate="trigger.Email.active ? 'required|max:384000' : ''"
                                data-vv-as="email message"
                        ></textarea>
                        <br />
                        <span v-show="errors.has('message')" class="status-block error-block field-error">{{ errors.first('message') }}</span>
                        <a @click="showMergeFields = true" class="">Show Available Merge Fields</a>
                    </div>
                </div>
            </div>

          <br />
          <div class="horizontal-form-row" v-show="trigger.Email.active && trigger.Attachment.Document.id">
            <label class="horizontal-form-label" for="attach_documents">&nbsp;</label>
            <div class="horizontal-form-input-container">
              <div class="horizontal-form-input" :class="{'has-error': errors.has('attach_documents') }">
                <checkbox
                  id="attach_documents"
                  name="attach_documents"
                  v-model="trigger.Attachment.to_email"
                  label="Attach documents"
                ></checkbox>

              </div>
            </div>
          </div>

        </div>


        <div class="form-section" v-show="active =='sms'" >
            <div class="horizontal-form-row">
                <div class="horizontal-form-input-container">
                    <div class="horizontal-form-input">
                        <div class="checkbox">
                            <input
                                    type="checkbox"
                                    v-model="trigger.SMS.active"
                                    id="sms_active"
                                    name="sms_active"
                                    data-name="sms_active"
                                    class="w-checkbox-input">
                            <label
                                    for="sms_active"
                                    class="w-form-label">Send an SMS when this is triggered.</label>
                        </div>
                    </div>

                </div>
            </div>

            <div class="horizontal-form-row" v-show="trigger.SMS.active">
                <label class="horizontal-form-label" for="subject">Image URL</label>

                <div class="horizontal-form-input-container">
                    <div class="horizontal-form-input" style="width: 400px; max-width: 100%;" :class="{'has-error': errors.has('media_url') }">
                        <input
                                id="media_url"
                                name="media_url"
                                class="input w-input"
                                v-model="trigger.SMS.media_url"
                                v-validate="trigger.SMS.active ? 'required|max:1000' : ''"
                                data-vv-as="media URL"
                        />
                    </div>
                    <span v-show="errors.has('media_url')" class="status-block error-block field-error">{{ errors.first('media_url') }}</span>
                </div>
            </div>




            <div class="horizontal-form-row" v-show="trigger.SMS.active">
                <label class="horizontal-form-label" for="message">SMS Content</label>
                <div class="horizontal-form-input-container">
                    <div class="horizontal-form-input" style="width: 400px" :class="{'has-error': errors.has('sms_message') }">
                        <textarea
                                id="sms_message"
                                rows="8"
                                name="sms_message"
                                class="input w-input"
                                v-model="trigger.SMS.message"
                                v-validate="trigger.SMS.active ? 'required|max:160' : ''"
                                data-vv-as="sms message"
                        ></textarea>
                        <br />
                        <span v-show="errors.has('sms_message')" class="status-block error-block field-error">{{ errors.first('sms_message') }}</span>
                        <a @click="showMergeFields = true" class="">Show Available Merge Fields</a>
                    </div>
                </div>
            </div>

        </div>


        <div class="modal-footer">
            <a class="close-link" @click="$emit('close')">Cancel</a>
            <button :disabled="isLoading($options.name)" class="w-button primary-btn" @click="save">Save</button>
            <span v-show="isLoading($options.name)" >
                <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
            </span>
        </div>


        <modal v-if="showMergeFields" size="md" allow-click-away="true" @close="showMergeFields = false">
            <div slot="header">
                <h3>
                    Available Merge Fields
                </h3>
                <p>
                    You can enter the following merge fields in the email you create. Simply include the text from the "text" column in the email message box and the corresponding field will be inserted in the email that is generated to each tenant.
                </p>

            </div>

            <div slot="body" size="lg">
                <div class="table">
                    <div class="table-row">
                        <div class="table-head">Field</div>
                        <div class="table-head">Text</div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell">Tenant First Name</div>
                            <div class="table-cell">[FIRSTNAME]</div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell">Tenant Last Name</div>
                        <div class="table-cell">[LASTNAME]</div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell">Tenant Email Address</div>
                        <div class="table-cell">[EMAIL]</div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell">Tenant Phone</div>
                        <div class="table-cell">[PHONE]</div>
                    </div>

                    <div class="table-row">
                        <div class="table-cell">Rent</div>
                        <div class="table-cell">[RENT]</div>
                    </div>

                    <div class="table-row">
                        <div class="table-cell">Space Address</div>
                        <div class="table-cell">[ADDRESS]</div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell">Space Number</div>
                        <div class="table-cell">[NUMBER]</div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell">Space City</div>
                        <div class="table-cell">[CITY]</div>
                    </div>

                    <div class="table-row">
                        <div class="table-cell">Space State</div>
                        <div class="table-cell">[STATE]</div>
                    </div>

                    <div class="table-row">
                        <div class="table-cell">Space Zip code/Postal Code</div>
                        <div class="table-cell">[ZIP]</div>
                    </div>

                    <div class="table-row">
                        <div class="table-cell">Invoice Balance (For past due triggers)</div>
                        <div class="table-cell">[BALANCE]</div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell">Invoice Due Date (For past due triggers)</div>
                        <div class="table-cell">[BILLDAY]</div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="w-button primary-btn" @click="showMergeFields = false">Close</button>
                </div>
            </div>
        </modal>




    </div>

</template>


<script type="text/babel">
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import api from '../../assets/api.js';
    import Dropdown from '../assets/Dropdown.vue';
    import DropdownMenu from '../assets/DropdownMenu.vue';
    import Autocomplete from '../assets/Autocomplete.vue';

    import Modal from '../assets/Modal.vue';
    import Tabs from '../assets/Tabs.vue';
    import Checkbox from '../assets/Checkbox.vue';
    import { mapGetters } from 'vuex';
    export default {

        name: "EditTrigger",
        data() {
            return {
                newEventType: '',
                newLeaseStanding: '',
                showMergeFields: false,
                active: 'details',
                open: '',
                trigger:{
                    id: '',
                    name:'',
                    description: '',
                    trigger_reference: '',
                    start: '',
                    repeat: '',
                    max_repeat: '',
                    deny_access: '',
                    overlock: '',
                    lease_standing_id: '',
                    Event: {
                        id: '',
                        active: false,
                        contact_id: '',
                        event_type_id: '',
                        todo: '',
                        details: ''
                    },
                    Fee: {
                        id: null,
                        active: false,
                        type: '$',
                        amount: '',
                        reference: 'Past Due Amount',
                        product_id:'',
                        Product: {
                            id:'',
                            name: ''
                        }
                    },
                    Email: {
                        id: null,
                        active: false,
                        subject: 'Late Fee Applied',
                        message: '[FIRSTNAME] [LASTNAME], \r\n\r\nYour account is past due.  Please pay your bill immediately to continue to access to your belongings.',
                        include_attachments: false,
                    },
                    SMS: {
                        id: null,
                        active: false,
                        media_url: '',
                        message: '',
                    },
                    Attachment: {
                        id: null,
                        active: 0,
                        document_id: null,
                        to_print: true,
                        to_email: true,
                        Document:{
                            id:null,
                            name:null,
                            document_type: ''
                        }

                    }
                },
                trigger_list: [
                        'After start of lease',
                        'After account is past due',
                        'Before end of lease'
                ],
                fee_references:[ 'Past Due Amount', 'Rent'],
                products:[],
                documents: [],
                hasSubmitted: false,
                admins: [],
                event_types: [],
                lease_standings: ['Good', 'Past Due', 'Overlock', 'Pre-Lien', 'Lien', 'Auction'],
                menuOptions: [
                    {
                        icon: '',
                        key: 'details',
                        label: 'Details'
                    },
                    {
                        icon: '',
                        key: 'events',
                        label: 'Events'
                    },
                    {
                        icon: '',
                        key: 'fees',
                        label: 'Fees'
                    },
                    {
                        icon: '',
                        key: 'email',
                        label: 'Email'
                    },
                    {
                        icon: '',
                        key: 'sms',
                        label: 'SMS'
                    },

                ]

            }
        },
        components:{
            Loader,
            Status,
            Dropdown,
            Autocomplete,
            Modal,
            Tabs,
            Checkbox,
            DropdownMenu
        },
        props:[
            'selected'
        ],
        created(){
            this.fetchDocuments();
            this.fetchAdmins();
            this.fetchEventTypes();
            this.fetchLeaseStandings();
            if(this.selected && this.selected.id){

                var trigger = JSON.parse(JSON.stringify(this.selected));
                this.trigger.id = trigger.id;
                this.trigger.name =  trigger.name;
                this.trigger.description = trigger.description;
                this.trigger.start = trigger.start;
                this.trigger.repeat = trigger.repeat;
                this.trigger.deny_access = trigger.deny_access;
                this.trigger.overlock = trigger.overlock;
                this.trigger.lease_standing_id = trigger.lease_standing_id;
                this.trigger.max_repeat = trigger.max_repeat;

                switch(trigger.trigger_reference){
                    case "start_of_lease":
                        this.trigger.trigger_reference = 'After start of lease';
                        break;
                    case "past_due":
                        this.trigger.trigger_reference = 'After account is past due';
                        break;
                    case "end_of_lease":
                        this.trigger.trigger_reference = 'Before end of lease';
                        break;
                    default:
                }


                if(trigger.Fee.id){

                    this.trigger.Fee.id = trigger.Fee.id;
                    this.trigger.Fee.amount = trigger.Fee.amount;
                    this.trigger.Fee.active = trigger.Fee.active;
                    this.trigger.Fee.product_id = trigger.Fee.Product.id;
                    this.trigger.Fee.Product.name = trigger.Fee.Product.name;
                    this.trigger.Fee.Product.id = trigger.Fee.Product.id;

                    switch(trigger.Fee.reference){
                        case "rent":
                            this.trigger.Fee.reference = 'Rent';
                            break;
                        case "pat_due":
                            this.trigger.Fee.reference = 'Past Due Amount';
                            break;
                        default:
                    }

                    switch(trigger.Fee.type){
                        case "dollar":
                            this.trigger.Fee.type = '$';
                            break;
                        case "percent":
                            this.trigger.Fee.type = '%';
                            break;
                        default:
                    }
                }
                if(trigger.Email.id){
                    this.trigger.Email.id = trigger.Email.id;
                    this.trigger.Email.subject = trigger.Email.subject;
                    this.trigger.Email.message = trigger.Email.message;
                    this.trigger.Email.active = trigger.Email.active;
                }

                if(trigger.SMS.id){
                    this.trigger.SMS.id = trigger.SMS.id;
                    this.trigger.SMS.media_url = trigger.SMS.media_url;
                    this.trigger.SMS.message = trigger.SMS.message;
                    this.trigger.SMS.active = trigger.SMS.active;
                }


                if(trigger.Attachment.id){
                    this.trigger.Attachment.id = trigger.Attachment.id;
                    this.trigger.Attachment.document_id = trigger.Attachment.document_id;
                    this.trigger.Attachment.active = trigger.Attachment.active;
                    this.trigger.Attachment.to_print = trigger.Attachment.to_print;
                    this.trigger.Attachment.to_email = trigger.Attachment.to_email;
                    this.trigger.Attachment.Document = trigger.Attachment.Document;
                }

                if(trigger.Event.id){
                    this.trigger.Event.id = trigger.Event.id;
                    this.trigger.Event.contact_id = trigger.Event.contact_id || -1;
                    this.trigger.Event.event_type_id = trigger.Event.event_type_id;
                    this.trigger.Event.todo = trigger.Event.todo;
                    this.trigger.Event.active = trigger.Event.active;
                    this.trigger.Event.details = trigger.Event.details;
                }
            }
        },
        computed:{
            ...mapGetters({
                getUserData: 'authenticationStore/getUserData'
            }),
            documentList(){
                if(!this.documents.length) return [];
                return this.documents.map(d => d.name + " (" + d.document_type + ")")
            },
            getDocumentName(){
                return this.documents
                    .filter(d => d.id === this.trigger.Attachment.document_id)
                    .map(d => d.name + " (" + d.document_type + ")")[0]
            }
        },

        methods:{
            async saveEventType(){
                if(!this.newEventType) return;
                let r = await api.post(this, api.EVENTS + 'event-types', {name: this.newEventType})
                await this.fetchEventTypes();
                this.trigger.Event.event_type_id = r.event_type_id;
                this.newEventType = '';
                this.$refs.event_type.away();
                this.$forceUpdate();

            },
            async saveLeaseStanding(){
                if(!this.newLeaseStanding) return;
                let r = await api.post(this, api.SETTINGS + 'lease-standings', {name: this.newLeaseStanding})
                this.fetchLeaseStandings();
                this.trigger.lease_standing_id = r.lease_standing_id;
                this.newLeaseStanding = '';
                this.$refs.lease_standing.away();

            },


            async fetchLeaseStandings(){
                api.get(this, api.SETTINGS + 'lease-standings').then(r => {
                    this.lease_standings = r.lease_standings;
                });
            },
            async fetchEventTypes(){
                api.get(this, api.EVENTS + 'event-types').then(r => {
                    this.event_types = r.event_types;
                });
            },
            async fetchAdmins(){
                api.get(this, api.ADMIN).then(r => {
                    this.admins = r.admins.filter(a => a.contact_id !== this.getUserData.id ).map(a => {
                        return {
                            id: a.contact_id,
                            name: a.first + ' ' + a.last
                        }
                    });
                    this.admins.unshift({
                        id: -1,
                        name: 'Everyone'
                    })
                    this.admins.unshift({
                        id: this.getUserData.id,
                        name: 'Me'
                    })
                });
            },
	        countSectionError(section){
		        if(!this.errors || !this.errors.items || !this.errors.items.length) return false;
		        return this.errors.items.filter(i => i.scope == section).length;
	        },

            setDocument(documentName){

                if(!documentName){
                    this.trigger.Attachment.id = null;
                    this.trigger.Attachment.document_id = null;
                    this.trigger.Attachment.Document = {
                        id:null,
                        name:null,
                        document_type: ''
                    };


                    return false;
                }

                this.trigger.Attachment.Document = this.documents.filter(function(d){
                    return d.name + " (" + d.document_type + ")" == documentName;
                }).map(function(d){
                    return {
                        id: d.id,
                        document_type: d.document_type,
                        name: d.name
                    }

                })[0];

                this.trigger.Attachment.document_id = this.trigger.Attachment.Document.id;
                console.log(this.trigger.Document);
            },
            fetchDocuments(){
                api.get(this, api.DOCUMENT).then(r => {
                    this.documents = r.documents;
                });
            },
            searchProducts(search){

                this.trigger.Fee.Product.name = search || '';
                var params = {
                    all: true,
                    search: search
                };

                api.get(this, api.SEARCH_PRODUCTS + '?type=product&type=late&search=' + search).then(r => {
                    this.products = r.products;
                });

            },

            setProduct(product){
                this.product = product || {};
                if(!product.value) {
                    this.trigger.Fee.Product = {
                        id: '',
                        name:''
                    }
                    return;
                }
                this.trigger.Fee.product_id = product.value;
                this.trigger.Fee.Product = {
                    name: product.label,
                    id: product.value
                }

            },
            isActive(tab){
                return tab == this.active;
            },

            save(){
                if(this.trigger.Fee.Product.length === undefined){
                    this.hasSubmitted = true;
                }

	            this.validate(this).then(status => {
                    if(!status) return;

                    if(!this.isLoading(this.$options.name)){
                        this.errorClear(this.$options.name);
                        this.startLoading(this.$options.name);

                        var trigger = JSON.parse(JSON.stringify(this.trigger));

                        switch(trigger.Fee.type){
                            case "$":
                                trigger.Fee.type = 'dollar';
                                break;
                            case "%":
                                trigger.Fee.type = 'percent';
                                break;
                            default:
                        }

                        switch(trigger.Fee.reference){
                            case "Rent":
                                trigger.Fee.reference = 'rent';
                                break;
                            case "Past Due Amount":
                                trigger.Fee.reference = 'past_due';
                                break;
                            default:
                        }

                        if(trigger.Fee.Product.id){
                            trigger.Fee.product_id = trigger.Fee.Product.id;
                        }

                        switch(trigger.trigger_reference.toLowerCase()){
                            case "after start of lease":
                                trigger.trigger_reference = 'start_of_lease';
                                break;
                            case "after account is past due":
                                trigger.trigger_reference = 'past_due';
                                break;
                            case "before end of lease":
                                trigger.trigger_reference = 'end_of_lease';
                                break;
                            default:
                        }

                        trigger.Fee.active = (trigger.Fee.active == true)?1:0;
                        trigger.Email.active = (trigger.Email.active == true)?1:0;
                        trigger.SMS.active = (trigger.SMS.active == true)?1:0;
                        trigger.Event.active = (trigger.Event.active == true) ? 1:0;

                        if(trigger.Attachment.Document.id){
                            trigger.document_id = trigger.Attachment.Document.id;
                        }

                        if(!trigger.id){
                            api.post(this, api.TRIGGERS, trigger).then(r => {
                                this.$emit('refetch');
                                this.$emit('close');
                            });

                        } else {
                            api.put(this, api.TRIGGERS + trigger.id, trigger).then(r => {
                                this.$emit('refetch');
                                this.$emit('close');
                                this.hasSubmitted = false;
                            });
                        }

                    }
                });
            },
            away(){
                return this.openSuggestion = false;
            }

        },
        watch:{
            'trigger.Fee.Product'(){
                if(this.trigger.Fee.Product != undefined){
                    this.hasSubmitted = false;
                }
            }
        },

    }


</script>
<style>
    input.form-control.inline {
        width: 65px;
    }

    .show {
        display: inline-block !important;
    }
</style>
