
<template>
    <div class="section-content">
        <div class="primary-section-content">
            <div class="row ma-0">
                <button @click="showAdd = true" class="w-button primary-btn">
                    <span class="icon"></span> Add Trigger
                </button>
            </div>

            <div class="content-view">
                <v-data-table 
                    fixed-header 
                    disable-pagination 
                    hide-default-footer
                    :headers="triggerHeaders" 
                    :items="triggers"
                    height="250px"
                ></v-data-table>
                <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
                <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>
            </div>
        </div>


        <modal v-if="showAdd" size="lg" allow-click-away="false" @close="closeWindow">
            <div slot="header">
                <h3>Add Trigger</h3>
            </div>
            <edit-trigger size="lg" slot="body" @close="closeWindow" @refetch="fetchData">
            </edit-trigger>
        </modal>



        <modal v-if="showEdit" size="lg" allow-click-away="false" @close="closeWindow">
            <h3 slot="header">Edit Trigger</h3>
            <edit-trigger size="lg" slot="body" :selected="selected" @close="closeWindow" @refetch="fetchData">
            </edit-trigger>
        </modal>

        <hb-modal v-model="showDelete" show-help-link v-if="showDelete" size="medium" title="Delete Trigger" @close="closeWindow">
            <template v-slot:content>
                <div class="py-4 px-6">
                   Are you sure you want to delete the selected trigger?
                   <br /><br />
                   This action cannot be undone.
                </div>
            </template>
            <template v-slot:actions>
                <hb-btn color="destructive" @click="deleteConfirm">Delete</hb-btn>
            </template>
        </hb-modal>
    </div>
</template>

<script type="text/babel">
    import Dropdown from '../assets/Dropdown.vue';
    import { mapGetters, mapActions } from 'vuex';
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import api from '../../assets/api.js';

    import Modal from '../assets/Modal.vue';
    import EditTrigger from './EditTrigger.vue';

    export default {
        name: "EventSettings",
        data() {
            return {
                selected: {},
                triggerHeaders:[{ text: 'Triggers', value: 'name' },],
                showDelete: false,
                showAdd:false,
                showEdit:false
            }
        },
        components:{
            Dropdown,
            Status,
            Loader,
            Modal,
            EditTrigger
        },
        created(){
            //this.fetchData();
            this.getTriggers();
        },
        computed:{
            ...mapGetters({
                triggers: 'triggersStore/triggers'
            })
        },
        filters: {
            triggerReference(reference){
                switch (reference){
                    case "start_of_lease":
                        return " from the start of lease";
                        break;
                    case "end_of_lease":
                        return " from the end of lease";
                        break;
                    case "past_due":
                        return " after a payment is due";
                        break;
                    default:
                        return " ";
                }
            }
        },
        methods:{
            ...mapActions({
                getTriggers: 'triggersStore/getTriggers'
            }),
            closeWindow(){
               this.showAdd = false;
               this.showEdit = false;
               this.showDelete = false;
            },

            fetchData(){
                api.get(this, api.TRIGGERS).then(r => {

                    this.triggers = r.triggers;
                });
            },

            editItem(trigger){
                this.selected = trigger;
                this.showEdit = true;
            },

            deleteItem(trigger){
                this.selected = trigger;
                this.showDelete = true;
            },
            deleteConfirm(){
                api.delete(this, api.TRIGGERS, this.selected.id).then(r => {
                    this.showDelete = false;
                    this.fetchData();
                });
            },

        }
    }
</script>

<style>
    .subdued {
        color: #a5adb4;
        line-height: 18px;
    }
</style>